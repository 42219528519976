<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ deviceDataItem.id ? '編輯記憶體' : '新增記憶體' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 名稱 -->
        <validation-provider
          #default="{ errors }"
          name="名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                名稱
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="deviceData.name"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="deviceDataItem.name ? deviceDataItem.name : '請輸入名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 型號 -->
        <validation-provider
          #default="{ errors }"
          name="型號"
          rules="required"
        >
          <b-form-group label-for="serialNumber">
            <template #label>
              <label class="mb-0">
                型號
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="serialNumber"
              v-model="deviceData.serialNumber"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="deviceDataItem.serialNumber ? deviceDataItem.serialNumber : '請輸入型號'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 品牌 -->
        <b-form-group label-for="brand_id">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                品牌
              </label>

              <span>
                <div
                  v-b-tooltip.hover.v-secondary
                  title="品牌設定"
                  class="d-flex align-items-center actions-link-btn-sm"
                  @click="() => { $refs.brandExplorerModal.getData(null) }"
                >
                  <!-- @click="() => { $refs.brandExplorerMainModal.getData() }" -->
                  <b-img
                    src="/admin/images/table/settings.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </span>
            </div>
          </template>

          <v-select
            v-model="deviceData.brand_id"
            :options="brandsOptions"
            :clearable="false"
            label="name"
            :reduce="option => option.id"
            placeholder="請選擇品牌"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 內存大小 -->
        <b-form-group label-for="size">
          <template #label>
            <label class="mb-0">
              內存大小(GB)
            </label>
          </template>

          <b-form-input
            id="size"
            v-model="deviceData.size"
            type="number"
            placeholder="請輸入記憶體大小"
          />
        </b-form-group>

        <!-- 購買日期 -->
        <b-form-group label-for="purchase_date">
          <template #label>
            <label class="mb-0">
              購買日期
            </label>
          </template>

          <flat-pickr
            v-model="deviceData.purchase_date"
            class="date-form-control form-control"
            :config="dateConfig"
            placeholder="YYYY-MM-DD"
          />
        </b-form-group>

        <!-- 健康度 -->
        <b-form-group label-for="health">
          <template #label>
            <label class="mb-0">
              健康度
            </label>
          </template>

          <vue-slider
            v-model="deviceData.health"
            :min="0"
            :max="100"
          />
        </b-form-group>

        <div>
          <label>記憶體說明</label>
          <b-form-textarea
            v-model="deviceData.description"
            :placeholder="deviceDataItem.description ? deviceDataItem.description : '請輸入記憶體說明'"
            :state="deviceData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="deviceData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ deviceData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

    <brand-explorer-modal
      ref="brandExplorerModal"
      brand-explorer-id="brandExplorerModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />
    <!-- @call-back-data="(item) => callbackData(item)" -->

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BFormTextarea,
  BImg, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import language from 'flatpickr/dist/l10n/zh-tw'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import BrandExplorerModal from '@/layouts/components/Modal/brand-modal/brandModal.vue'
import { useDeviceMemoryList, useDeviceSetting } from '../useDevice'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    // BFormSpinbutton,
    BFormTextarea,

    vSelect,
    VueSlider,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BrandExplorerModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    deviceDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      password,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.reseDeviceData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.deviceData.description.length > this.maxChar) {
        this.useAlertToast(false, '記憶體說明字數超過上限')
        return
      }

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '名稱',
        serialNumber: '型號',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.deviceData[Object.keys(requiredFields)[i]] === null || this.deviceData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      if (this.deviceDataItem.id) {
        this.setDeviceMemoryUpdate({
          memory_id: this.deviceDataItem.id,
          data: {
            ...this.deviceData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.reseDeviceData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setDeviceMemoryCreate({
          ...this.deviceData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.reseDeviceData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData()
        .then(response => {
          const { brands } = response.data.data
          this.brandsOptions = brands
          const findObject = brands.find(el => el.id === this.deviceDataItem.brand_id)

          if (findObject === undefined) {
            this.deviceData.brand_id = null
          }
        })
    },

    // (獲取)資料
    getData() {
      this.getMetaListData()
        .then(response => {
          const { brands } = response.data.data
          this.brandsOptions = brands

          const resolveData = {
            ...this.syncObject(this.blankMemoryData, this.deviceDataItem),
          }

          const findObject = brands.find(el => el.id === this.deviceDataItem.brand_id)

          if (findObject === undefined) {
            resolveData.brand_id = null
          }
          this.deviceData = resolveData
          this.$bvModal.show('update-modal')
        })
        .catch(() => {
          const resolveData = {
            ...this.syncObject(this.blankMemoryData, this.deviceDataItem),
          }
          this.deviceData = resolveData
          this.$bvModal.show('update-modal')
        })
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      blankMemoryData,
      setDeviceMemoryCreate,
      setDeviceMemoryUpdate,
    } = useDeviceMemoryList()

    const {
      getMetaListData,
      brandsOptions,
      syncObject,
    } = useDeviceSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const deviceData = ref(JSON.parse(JSON.stringify(blankMemoryData)))

    const reseDeviceData = () => {
      deviceData.value = JSON.parse(JSON.stringify(blankMemoryData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(reseDeviceData)

    const dateConfig = {
      locale: language.zh_tw,
      dateFormat: 'Y-m-d',
      allowInput: true,
      static: true,
    }

    return {
      isBusy,
      dateConfig,

      deviceData,
      brandsOptions,
      blankMemoryData,
      setDeviceMemoryCreate,
      setDeviceMemoryUpdate,
      getMetaListData,

      reseDeviceData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
